import type { FunctionComponent } from "react";

import { PageSeo } from "scmp-app/components/meta";
import {
  PostiesAdditionalLinkTags,
  PostiesDescription,
  PostiesTitle,
} from "scmp-app/components/posties/posties-seo/consts";

import PostiesImage from "./posties-image.png";

export const PostiesSeo: FunctionComponent = () => (
  <PageSeo
    additionalLinkTags={PostiesAdditionalLinkTags}
    additionalMetaTags={undefined}
    description={PostiesDescription}
    openGraph={{
      description: PostiesDescription,
      images: [{ url: PostiesImage.src }],
      siteName: PostiesTitle,
      title: PostiesTitle,
    }}
    options={{
      customMerge(key) {
        if (key === "additionalLinkTags") {
          return (_, source: unknown[]) => source;
        }
      },
    }}
    title={PostiesTitle}
    twitter={undefined}
  />
);

PostiesSeo.displayName = "PostiesSeo";
